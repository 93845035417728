import * as Sentry from "@sentry/browser";

if (process.env.NODE_ENV === "production") {
  var sampleRate = 0.1;
} else {
  var sampleRate = 0.5;
}

var integrations = [
  Sentry.browserTracingIntegration(),
  Sentry.replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
  }),
];

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: integrations,

  // Performance Monitoring
  tracesSampleRate: sampleRate, //  Capture 100% of the transactions

  // Session Replay
  replaysSessionSampleRate: sampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.NODE_ENV,
});
